<template>
  <div class="install">
    <model title="安装">
      <h3>npm安装</h3>
      <p>推荐使用 npm 的方式安装，它能更好地和 webpack 打包工具配合使用。</p>
      <lh-code-editor :downloadButton="false" :copyButton="false" backgroundColor="black" v-model="iCode" readonly></lh-code-editor>
      <p>
        安装完 lianghang-ui 后即可引入ui组件，引入组件请查看下一节
          <lh-router-link to="/start" margin="3px" fontSize="18px" color="#409EFF">快速上手</lh-router-link>
        。
      </p>
      <h3>CDN</h3>
      <p>
        目前可以通过
        <lh-router-link margin="3px" to="https://unpkg.com/browse/lianghang-ui/" target="_blank" color="#409EFF" fontSize="18px">unpkg.com/lianghang-ui</lh-router-link>
        获取到最新版本的资源，在页面上引入 js 文件即可开始使用。
      </p>
      <lh-code-editor :downloadButton="false" :copyButton="false" backgroundColor="black" v-model="cdnCode" readonly></lh-code-editor>
      <p>
        我们建议使用 CDN 引入 lhUI 的用户在链接地址上锁定版本，以免将来 lhUI 升级时受到非兼容性更新的影响。锁定版本的方法请查看
        <lh-router-link margin="3px" to="https://unpkg.com/" target="_blank" color="#409EFF" fontSize="18px">unpkg.com</lh-router-link> 。
      </p>
      <p>通过 CDN 的方式我们可以很容易地使用 lhUI 写出一个 Hello world 页面。</p>
      <lh-code-editor v-model="htmlCode" readonly></lh-code-editor>
    </model>
  </div>
</template>

<script>
import Model from '../../components/Model'
export default {
  name:"Install",
  components: { Model },
  data(){
    return{
      iCode:"npm install lianghang-ui",
      cdnCode:"<!-- 引入组件库 -->\n<script src=\"https://unpkg.com/lianghang-ui/dist/lianghang-ui.umd.min.js\"></scrip"+"t>",
      htmlCode:
        "<!DOCTYPE html>\n"+
        "<html>\n"+
        "<head>\n"+
        "  <meta charset=\"UTF-8\">\n"+
        "</head>\n"+
        "<body>\n"+
        "  <div id=\"app\">\n"+
        "    <lh-button @click=\"visible = true\">Button</lh-button>\n"+
        "    <lh-dialog :visible.sync=\"visible\" title=\"Hello world\">\n"+
        "      <p>Try lhUI</p>\n"+
        "    </lh-dialog>\n"+
        "  </div>\n"+
        "</body>\n"+
        "  <!-- import Vue before lhUI -->\n"+
        "  <script src=\"https://unpkg.com/vue@2/dist/vue.js\"></scrip"+"t>\n"+
        "  <!-- import JavaScript -->\n"+
        "  <script src=\"https://unpkg.com/lianghang-ui/dist/lianghang-ui.umd.min.js\"></scrip"+"t>\n"+
        "  <scrip"+"t>\n"+
        "    new Vue({\n"+
        "      el: '#app',\n"+
        "      data: function() {\n"+
        "        return { visible: false }\n"+
        "      }\n"+
        "    })\n"+
        "  </scrip"+"t>\n"+
        "</html>"
    }
  }
}
</script>

<style lang="less" scoped>
.install{
  p{
    text-indent: 2em;
  }
}
</style>